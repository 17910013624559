























































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import WidgetTypesModule from "@/store/clients/WidgetTypes.module";
import { WidgetType } from "@/types/widgetType";
const WidgetTypes = getModule(WidgetTypesModule, store);

@Component
export default class GettingStartedHelp extends Vue {
  tab = null;

  get widgetTypes(): WidgetType[] {
    return WidgetTypes.allAddable;
  }
}
